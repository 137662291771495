@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

.color-nav {
    background-color: #171923;
}

.navbar-dark .navbar-brand {
    color: aqua;
    font-family: 'Electrolize', sans-serif
}

body {
    background-color: #1A202C;
}

.navbar-nav .nav-link {
    color: white !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: aqua !important;
}

h6 {
    color: white
}

span {
    color: aqua
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}


.line-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.first_line,
.second_line {
    white-space: nowrap;
    /* keep text in one line */
    overflow: hidden;
    /* hide text behind the cursor */
    margin: 0 auto;
    text-align: center;
    /* font-family: "Major Mono Display", monospace; */
    /* font-size: 2rem; */
    font-weight: bold;
}

.first_line {
    border-right: .05em solid transparent;
    /* define animation types for text + cursor */
    animation: typewriter_1 2.5s steps(40, end) 1s 1 normal both,
        first_cursor .5s steps(40) 8;

}

.second_line {
    border-right: .05em solid transparent;
    /* define animation types for text + cursor */
    animation: typewriter_2 2.5s steps(40, end) 1s 1 normal both,
        second_cursor .5s step-end infinite;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

@keyframes typewriter_1 {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes typewriter_2 {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes first_cursor {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: aqua
    }

    51%,
    100% {
        border-color: transparent;
    }
}

@keyframes second_cursor {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: aqua
    }
}


.container-button {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.container-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.split-about {
    display: flex;
    justify-content: center;
    gap: 5rem;
    padding-top: 3%;
}

.split-about p {
    color: white;
    line-height: 1.8em;
}

.container-projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5%;
}

.project-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-top: 3%;
}

.card {
    background-color: #2D3748;
}

.card-title {
    color: white;
    font-weight: bold;
}

.card-text {
    color: white
}

.button-arrangement {
    display: flex;
    gap: 10px
}

.project-buttons {
    background-color: rgba(255, 255, 255, 0.08);
    color: #9EB9D4;
    font-weight: 600;
    border-color: transparent;
}

.project-buttons:hover {
    background-color: rgba(255, 255, 255, 0.16);
    color: #9EB9D4;
    font-weight: 600;
    border-color: transparent;
}

.project-buttons:not(:disabled):active {
    background-color: rgba(255, 255, 255, 0.20);
    color: #9EB9D4;
    font-weight: 600;
    border-color: transparent;
}

/* Puts SVG and button text on the same height */
.button-content-align {
    display: flex;
    align-items: center;
    gap: 5px
}

.badges {
    display: flex;
    gap: 5px;
    padding-top: 3%;
}

.badge {
    color: white;
    display: flex;
    gap: 5px;
    pointer-events: none;
}

.badges .badge {
    color: lightcyan;
}

.container-skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
}

.container-badges {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    gap: 5px;
    padding-top: 50px;
}

.container-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 5%;
}

.contact-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    gap: 5px;
    padding-top: 5%;
    padding-bottom: 3%;
    text-align: center;
}

.contact-content h2 {
    color: white;
    font-weight: bold;
    font-size: 1.8em;
}

.contact-content p {
    color: #4A5568;
    font-size: 1.25rem;
}

.contact-content a {
    text-decoration: none;
    color: aqua;
    font-weight: bold;
}

.contact-content a:hover {
    text-decoration: none;
    color: rgb(53, 135, 135);
    font-weight: bold;
}

footer {
    padding-top: 3%;
}

.footer-content {
    background-color: #171923;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;
}


/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
    .container {
        margin-top: 20px;
    }

    .container-about {
        width: 40%;
    }

    .container-projects{
        width: 40%;
    }

    .container p {
        width: 50%;
    }

    .container-skills {
        width: 40%;
    }
    .container-contact {
        width: 40%;
    }
}

/* Large screens */
@media screen and (min-width: 1200px) {

    /* CSS rules for large screens */
    #about {
        scroll-margin-top: 6rem;
    }

    #skills {
        scroll-margin-top: 1rem;
    }
    
    #projects {
        scroll-margin-top: 2rem;
    }
    
    .container-about {
        width: 50%;
    }

    .container-projects {
        width: 50%;
    }

    .container p {
        width: 60%;
    }

    .container-skills {
        width: 50%;
    }

    .container-contact {
        width: 50%;
    }
}

/* Medium screens */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    /* CSS rules for medium screens */
    #about {
        scroll-margin-top: 6rem;
    }

    #skills {
        scroll-margin-top: 2rem;
    }

    #projects {
        scroll-margin-top: 2rem;
    }

    .container-about {
        width: 50%;
    }

    .container-projects {
        width: 50%;
    }

    .container p {
        width: 60%;
    }

    .container-skills {
        width: 50%;
    }

    .container-contact {
        width: 50%;
    }
}

/* Small screens */
@media screen and (min-width: 768px) and (max-width: 991px) {

    /* CSS rules for small screens */
    #about {
        scroll-margin-top: 6rem;
    }

    #skills {
        scroll-margin-top: -20px;
    }

    #projects {
        scroll-margin-top: 1rem;
    }

    .container-about {
        width: 80%;
    }

    .container-projects {
        width: 80%;
        padding-top: 10%;
    }

    .container-skills {
        width: 80%;
        padding-top: 15%;
    }

    .container-badges {
        width: 70%;
    }

    .container-contact {
        width: 80%;
        padding-top: 10%;
    }
}

/* Large smartphones */
@media screen and (min-width: 481px) and (max-width: 767px) {

    /* CSS rules for large smartphones */
    #about {
        scroll-margin-top: 18rem;
    }

    #skills {
        scroll-margin-top: 13rem;
    }

    #projects {
        scroll-margin-top: 14rem;
    }

    .first_line h1,
    .second_line h1 {
        font-size: 1.4rem;
        line-height: 1.2;
    }

    .container-about {
        width: 80%;
    }

    .container-projects {
        width: 80%;
        padding-top: 10%;

    }

    .split-about {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }

    .badges {
        padding-top: 5%;
    }

    .container-skills {
        width: 80%;
        padding-top: 15%;
    }

    .container-badges {
        width: 80%;
    }

    .container-contact {
        width: 80%;
        padding-top: 15%;
    }

    .container-contact .container-button {
        padding-top: 2%;
        padding-bottom: 10%;
    }

    .contact-content p {
        font-size: 18px;
    }
}

/* Small smartphones */
@media screen and (max-width: 480px) {

    /* CSS rules for small smartphones */
    #about {
        scroll-margin-top: 18rem;
    }

    #skills {
        scroll-margin-top: 11rem;
    }

    #projects {
        scroll-margin-top: 11rem;
    }

    .first_line h1,
    .second_line h1 {
        font-size: 1.4rem;
        line-height: 1.2;
    }

    .container-about {
        width: 80%;
    }

    .split-about {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
        padding-top: 5%;
    }

    .container-projects {
        width: 80%;
        padding-top: 30%;
    }

    .project-cards {
        padding-top: 10%;
    }

    .badges {
        padding-top: 5%;
        flex-wrap: wrap; /* So that the some badges go down on smaller screens if about to go out of bounds */
    }

    .container-skills {
        width: 80%;
        padding-top: 30%;
    }

    .container-badges {
        width: 80%;
    }

    .container-contact {
        width: 80%;
        padding-top: 20%;
    }

    .container-contact .container-button {
        padding-top: 2%;
        padding-bottom: 10%;
    }

    .contact-content {
        padding-top: 10%;
        padding-bottom: 5%;
    }

    .contact-content h2 {
        font-size: 1.5em;
    }

    .contact-content p {
        font-size: 18px;
    }
}

/* Tablets in landscape orientation */
/* @media screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) { */
    /* CSS rules for tablets in landscape orientation */
/* } */

/* Tablets in portrait orientation */
/* @media screen and (min-width: 481px) and (max-width: 767px) and (orientation: portrait) { */
    /* CSS rules for tablets in portrait orientation */
/* } */